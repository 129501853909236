<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="columns">
      <div class="column is-3 p-3" v-if="$screen.width > 768">
        <banner-left
          :pasiva="getLinea.pasiva ? true : false"
          :image="image"
          titulo="Contrato de línea"
          texto=""
        />
      </div>
      <div class="column is-9 text-left">
        <div class="columns">
          <div class="column is-12 text-left">
            <p class="text-2xl font-bold">
              Crear contrato
            </p>
            <p class="has-text-grey-light">
              Línea #{{ $route.params.idLinea }}
            </p>
          </div>
        </div>
        <extras-contrato
          :pasivo="getLinea.pasiva ? true : false"
          :capital="getLinea.capital_disponible"
          rules="required"
          @updatedCiclos="ciclosLength => (ciclos = ciclosLength)"
        />
        <div class="columns">
          <div class="column text-right">
            <b-button
              type="is-primary"
              @click="validate().then(res => createContrato(res))"
              >Crear contrato</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BannerLeft from "@/components/banners/BannerLeft";
import ExtrasContrato from "@/components/Lineas/extras/Contrato";
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  name: "Contrato",
  components: {
    BannerLeft,
    ValidationObserver,
    ExtrasContrato
  },
  data() {
    return {
      image: "/images/contrato_banner.svg",
      ciclos: 0
    };
  },
  methods: {
    createContrato(res) {
      if (!res) return;
      if (!this.getDataCreacion.contrato) return;
      if (this.ciclos < 1) {
        this.notification(
          "warning",
          "Sin ciclos del contrato",
          "Debes agregar un ciclo del contrato para continuar"
        );
        return;
      }
      this.$store
        .dispatch("lineascredito/createContrato", {
          capital: this.getDataCreacion.contrato.capital,
          numero: this.getDataCreacion.contrato.numero,
          descripcion: this.getDataCreacion.contrato.descripcion,
          fecha_firma: this.getDataCreacion.contrato.fecha_firma
            ? this.$moment(this.getDataCreacion.contrato.fecha_firma).format(
                "YYYY-MM-DD"
              )
            : null,
          vencimiento_contrato: this.getDataCreacion.contrato
            .vencimiento_contrato
            ? this.$moment(
                this.getDataCreacion.contrato.vencimiento_contrato
              ).format("YYYY-MM-DD")
            : null,
          linea_credito_id: this.$route.params.idLinea,
          ciclos: this.getDataCreacion.contrato.ciclos.map(x => {
            return {
              fecha_inicial: this.$moment(x[0]).format("YYYY-MM-DD"),
              fecha_final: this.$moment(x[1]).format("YYYY-MM-DD")
            };
          })
        })
        .then(() => {
          this.$router.push({
            name: "detalle_linea"
          });
        });
    }
  },
  computed: {
    ...mapGetters("lineascredito", [
      "getLinea",
      "getDisposiciones",
      "getGarantias",
      "getDataCreacion"
    ]),
    ...mapGetters("catalogos", ["getCostosExtras", "getMonedas"])
  }
};
</script>
